import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
//todo: larger fonts in numbers
const MissionInstrumentsPage = () => {
  return (
    <Layout pageTitle="INCUS Mission and Instruments">
      <h1>INCUS Mission Plan and Instruments</h1>
      <h4>
        INCUS is made possible by small size and low power consumption to support three low-cost SmallSats with advanced active and passive measurements of cloud dynamics in the global tropics.
      </h4>

      <Row>
        <Col md={6} className="align-self-center">
          <ol>
            <li><strong>Strong Heritage:</strong> Successful technology demos by NASA's Earth Science and Technology Office (RainCube and TEMPEST-D instruments) will be flown together on INCUS for the first time on one platform, thereby capitalizing on their combined capabilities to obtain simultaneous observations of the internal storm structure, rapidly evolving convective mass flux and updraft dynamics, and anvil cloud properties.</li>
            <br />
            <li><strong>Series of three SmallSats:</strong> Three SmallSats will be flown in close succession with three time intervals (30, 90, and 120 seconds) will be used to apply a novel time-differencing approach to estimate convective mass flux in tropical convective storms for the first time. </li>
            <br />
            <li><strong>Instruments:</strong> Three RainCube-like Ka-band radars (one on each SmallSat) and one TEMPEST-D-like radiometer (middle SmallSat only) provide both active and passive remote sensing observations of rapid changes in convective cloud depths and intensities across the global tropics.</li>
          </ol>

        </Col>
        <Col md={6}>
          <StaticImage
            alt="Illustration showing one of the INCUS satellites"
            src="../../images/incus_sat.png"
            layout="constrained"
            placeholder="blurred"
          />
        </Col>
      </Row>
      <h2>Anticipated Basic Specifications</h2>
      <StaticImage
        alt="Illustration showing the basic mission setup of INCUS, with three satellites passing over a line of convective storms, one at time 0, one 30 seconds later, and one 120 seconds after the first one."
        src="../../images/incus_full_diagram.png"
        layout="constrained"
        placeholder="blurred"
      />
      <div className="justify-content-center">
        <h3>Target Launch Date: October 2026</h3>
        <h3>Mission Duration: 2 Years</h3>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Inclination</td>
              <td>Tropical (anticipated inclination between 22.5&deg; and 39&deg;)</td>
            </tr>
            <tr>
              <td>Speed</td>
              <td>~7 km s<sup>-1</sup></td>
            </tr>
            <tr>
              <td>Orbit Duration</td>
              <td>~95 minutes</td>
            </tr>
            <tr>
              <td>Orbits per Day</td>
              <td>~15</td>
            </tr>
            <tr>
              <td>Instruments</td>
              <td>Three scanning Ka-band radars, one scanning radiometer</td>
            </tr>
            <tr>
              <td>Science Data</td>
              <td>Convective mass flux, 3D reflectivity, 5 passive microwave channels</td>
            </tr>
            <tr>
              <td>Spatial Resolution</td>
              <td>Ka-band radar: ~3.1 km; Radiometer: ~16 km</td>
            </tr>
            <tr>
              <td>Observational Coverage</td>
              <td>All longitudes, with latitudinal coverage dependent on inclination</td>
            </tr>
            <tr>
              <td>Mass</td>
              <td>~100 kg</td>
            </tr>
            <tr>
              <td>Power</td>
              <td>~190 Watts</td>
            </tr>
            <tr>
              <td>Data Rate</td>
              <td>Up to 2.2 Gb per day (per space craft)</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h2>Anticipated Instrument Performance</h2>
      <Row>
        <Col md={8} className="align-self-center">
          <h4>Ka-Band Radars</h4>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Horizontal Resolution</td>
                <td>~3.1 km</td>
              </tr>
              <tr>
                <td>Vertical Resolution</td>
                <td>~240 m</td>
              </tr>
              <tr>
                <td>Radar Sensitivity</td>
                <td>~12 dBZ</td>
              </tr>
              <tr>
                <td>Swath Width</td>
                <td>~9 km</td>
              </tr>
              <tr>
                <td>Instrument Weight</td>
                <td>~7 kg</td>
              </tr>
            </tbody>
          </Table>
          <h4>Radiometer (one satellite only)</h4>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Frequencies</td>
                <td>87, 165, 174, 178, 181 ± 0.5 GHz</td>
              </tr>
              <tr>
                <td>Horizontal Resolution</td>
                <td>~16 km</td>
              </tr>
              <tr>
                <td>Swath Width</td>
                <td>~1000 km</td>
              </tr>
              <tr>
                <td>Instrument Weight</td>
                <td>~3.8 kg</td>
              </tr>
            </tbody>
          </Table>

        </Col>
        <Col md={4}>
          <StaticImage
            alt="Illustration showing one of the INCUS satellites with its components labeled"
            src="../../images/incus_sat_diagram.png"
            layout="constrained"
            placeholder="blurred"
          />

        </Col>
      </Row>

    </Layout>

  )
}

export default MissionInstrumentsPage

